<template>
  <div>
    <h2 class="mb-base">{{$t('Stock Recalls')}}</h2>
    <head-component :noActions="true" :statusFilters="statusFilters" :selected="selected" @changeFilter="changeFilter" @filterValue="filterValue = $event" @loadShipments="loadStockRecalls"></head-component>
    <shipblu-table
     :sst="true"
      multiple
      v-model="selected"
      pagination
      :max-items="maximumItems"
      @search="handleSearch"
      @keyup.enter.native="handleSearch"
      search
      :data="stockRecalls"
      :tableLoader="tableLoader"
    >
      <template slot="thead">
        <shipblu-th>{{$t('Request ID')}}</shipblu-th>
        <shipblu-th>{{$t('Created On')}}</shipblu-th>
        <shipblu-th>{{$t('Merchant Name')}}</shipblu-th>
        <shipblu-th>{{$t('Number of SKUs')}}</shipblu-th>
        <shipblu-th>{{$t('Total Quantity')}}</shipblu-th>
        <shipblu-th>{{$t('Fulfillment Center')}}</shipblu-th>
        <shipblu-th>{{$t('Number Of Vehicles')}}</shipblu-th>
        <shipblu-th>{{$t('Received On')}}</shipblu-th>
        <shipblu-th>{{$t('Status')}}</shipblu-th>
        <shipblu-th>{{$t('Actions')}}</shipblu-th>
      </template>

      <template slot-scope="{ data }">
        <shipblu-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data"
          :to="{name: `${$store.state.AppActiveUser.userRole}-stock-recall-view`, params: {stockID: data[indextr].id}}">
          <shipblu-td :data="data[indextr].requestID">
            {{ data[indextr].id }}
          </shipblu-td>

          <shipblu-td :data="data[indextr].createdOn">
            {{ new Date(data[indextr].created).toLocaleDateString('fr-CA') }}
          </shipblu-td>

          <shipblu-td :data="data[indextr].merchant">
            {{ data[indextr].merchant.name }}
          </shipblu-td>

          <shipblu-td :data="data[indextr].numberOfSKU">
            {{ data[indextr].number_of_skus }}
          </shipblu-td>

          <shipblu-td :data="data[indextr].quantity">
            {{ data[indextr].total_quantity }}
          </shipblu-td>

          <shipblu-td :data="data[indextr].fulfillmentCenter">
            {{ data[indextr].fulfillment_center.name }}
          </shipblu-td>

          <shipblu-td :data="data[indextr].vehicles">
            {{ data[indextr].num_vehicles }}
          </shipblu-td>

          <shipblu-td :data="data[indextr].receivedOn">
            {{ data[indextr].received_date }}
          </shipblu-td>

          <shipblu-td :data="data[indextr].status" :style="`color: ${getStyle(data[indextr])}`">
            <div class="m-auto flex items-center justify-center font-semibold">
              <div><span class="status-icon mr-1"></span></div>
              <span class="capitalize">{{$t(getStatusLabel(data[indextr]))}}</span>
            </div>
          </shipblu-td>

          <shipblu-td>
            <div @click="removeAttribute($event)">
              <vs-dropdown
                vs-trigger-click
                class="dd-actions cursor-pointer"
                @click="selected = []"
              >
                <vs-button
                  type="border"
                  size="small"
                  icon-pack="feather"
                  icon="icon-more-horizontal"
                >
                </vs-button>
                <vs-dropdown-menu style="width: 190px">
                  <template>
                    <vs-dropdown-item
                      :disabled="data[indextr].status !== 'submitted'"
                      @click="addTracking(data[indextr])"
                    >
                      <span class="flex gap-2 items-center">
                        <feather-icon
                          icon="AlertCircleIcon"
                          svgClasses="h-4 w-4"
                        />
                        <span>{{$t('Mark as Ready')}}</span>
                      </span>
                    </vs-dropdown-item>
                  </template>
                </vs-dropdown-menu>
              </vs-dropdown>
            </div>
          </shipblu-td>
        </shipblu-tr>
      </template>
    </shipblu-table>
    <shipblu-pagination :maximumItems="maximumItems" :noOptions="true" :totalRows="totalRows" :currentPage="currentPage" @currentPage="currentPage = $event" />
  </div>
</template>

<script>
import common  from '@/assets/utils/common'
import {sendFulfillmentRequest} from '../../../http/axios/requestHelper.js'
import ShipbluTable from '../../../layouts/components/NewShipBluTable.vue'
import ShipbluTr from '../../../layouts/components/ShipBluTr.vue'
import ShipbluTh from '../../../layouts/components/ShipBluTh.vue'
import ShipbluTd from '../../../layouts/components/ShipBluTd.vue'
import HeadComponent from '../../merchant/components/Header.vue'
import i18nData from '../../../i18n/i18nData.js'
import ShipbluPagination from '../../../layouts/components/ShipbluPagination.vue'

export default {
  data () {
    return {
      maximumItems: process.env.VUE_APP_MAXIMUM_ITEMS_TABLE,
      selected: [],
      stockRecalls: [],
      statusFilters: [
        {
          name: 'All',
          value: 'all'
        },
        {
          name: 'Draft',
          value: 'draft'
        },
        {
          name: 'Submitted',
          value: 'submitted'
        },
        {
          name: 'Ready',
          value: 'ready'
        },
        {
          name: 'Outbounded',
          value: 'outbounded'
        },
        {
          name: 'Recalled',
          value: 'recalled'
        }
      ],
      filters: [],
      offset: 0,
      totalRows: 0,
      currentPage: (this.$route.query.page &&  Number(this.$route.query.page) > 0) ? Number(this.$route.query.page) : 1,
      searchVal: '',
      searchInProgress: false,
      searchedValue: ' ',
      stockStatus: '',
      tableLoader: false
    }
  },
  watch: {
    currentPage () {
      this.offset = (this.currentPage - 1) * this.maximumItems
      this.$router.push({
        query: {
          page: this.currentPage,
          filter: this.filters
        }
      }).catch(() => {})
      this.loadStockRecalls()
    },
    '$route.query.filter' () {
      if (!this.$route.query.filter) {
        this.filters = []
        this.loadStockRecalls()
      }
    }
  },
  methods: {
    addTracking (data) {
      this.stockStatus = 'ready'
      this.stockRequestID = data.id
      this.confirmAddTracking()
    },
    confirmAddTracking () {
      const tracking = {
        stock_request: this.stockRequestID,
        status: this.stockStatus
      }
      sendFulfillmentRequest(false, false, this, `api/v1/fc/stock-recalls/${this.stockRequestID}/tracking/`, 'post', tracking, true,
        () => {
          this.$vs.notify({
            color:'success',
            title:i18nData[this.$i18n.locale]['Success'],
            text:i18nData[this.$i18n.locale]['Stock recall'] + i18nData[this.$i18n.locale][' has been updated successfully!'],
            position: 'top-center'
          })
          this.stockStatus = ''
          this.loadStockRecalls()
        }
      )
    },
    removeAttribute (event) {
      event.preventDefault()
    },
    changeFilter () {
      const filtersTemp = this.filters
      this.filters = []
      filtersTemp.forEach(item => {
        this.filters.push(item)
      })
      this.filters.includes(this.filterValue) ? this.filters.splice(this.filters.indexOf(this.filterValue), 1) : this.filters.push(this.filterValue)
      if (this.filterValue === 'all') {
        this.filters = []
      }
      this.currentPage = 1
      this.$router.push({
        query: {
          page: this.currentPage,
          filter: this.filters
        }
      }).catch(() => {})
      this.loadStockRecalls()
    },
    getStyle (data) {
      return common.getOrderStatusColor(data.status)
    },
    getOrderStatusColor (stock) {
      return common.getOrderStatusColor(this.getStatusLabel(stock))
    },
    getStatusLabel (stock) {
      return common.getStatusLabel(stock, this.$store.state.AppActiveUser.userRole)
    },
    loadStockRecalls () {
      this.searchInProgress = true
      this.tableLoader = true
      const query = `?offset=${this.offset}&search=${this.searchVal}&limit=${this.maximumItems}&status=${this.filters}`
      sendFulfillmentRequest(true, false, this, `api/v1/fc/stock-recalls/${query}`, 'get', null, true,
        (response) => {
          this.stockRecalls = response.data.results
          this.tableLoader = false
          this.totalRows = response.data.count
          this.loadCreatedStocks()
          this.searchedValue = this.searchVal
        }
      )
      this.searchInProgress = false
    },
    handleSearch (search) {
      this.offset = 0
      this.currentPage = 1
      this.searchVal = search.target ? search.target.value : search
      return common.manageSearch(search, this.loadStockRecallSearch)
    },
    loadStockRecallSearch () {
      if (this.searchInProgress || this.searchedValue === this.searchVal) {
        return
      }
      this.loadStockRecalls()
    }
  },
  components: {
    HeadComponent,
    ShipbluTable,
    ShipbluTr,
    ShipbluTh,
    ShipbluTd,
    ShipbluPagination
  },
  created () {
    this.filters = this.$route.query.filter ? typeof (this.$route.query.filter) === 'string' ? Array(this.$route.query.filter) : this.$route.query.filter : []
    this.offset = (this.currentPage - 1) * this.maximumItems
    this.loadStockRecalls()
  }
}
</script>

<style lang="scss">
@import "@/assets/scss/vuexy/apps/email.scss";
.con-pagination-table{
  display: none;
}
</style>